<section id="collapse">
    <div class="blockcode pt-5">
        <div class="header">Collapse</div>
        <div class="description">Collapse</div>
        <div class="d-flex flex-column p-3 justify-content-center align-items-center border">
            <CDBBtn color="primary" style="margin-bottom: 1rem" (click)="content.toggleCollapse()">
                COLLAPSE BUTTON
            </CDBBtn>
            <CDBCollapse #content="cdbCollapse">
                <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum
                </span>
            </CDBCollapse>
        </div>
    </div>

    <div class="blockcode">
        <div class="header">Collapse</div>
        <div class="description">Opened Collapse</div>
        <div class="d-flex flex-column p-3 justify-content-center align-items-center border">
            <CDBBtn color="secondary" style="margin-bottom: 1rem" (click)="content2.toggleCollapse()">
                COLLAPSE BUTTON
            </CDBBtn>
            <CDBCollapse #content2="cdbCollapse" [isOpen]=true>
                <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor harum, molestias ducimus
                    dolorem,
                    ipsa asperiores sequi quaerat quidem non eius facere vitae pariatur at voluptatibus sint
                    blanditiis
                    facilis sunt accusamus quae quia voluptas, enim numquam! Maxime nulla omnis soluta sequi
                    consequatur
                    iusto repudiandae obcaecati, eius numquam delectus veniam possimus fugiat qui nobis neque
                    eveniet
                    quasi doloribus magnam amet nihil! Et perferendis ipsum repellat est deserunt. Mollitia labore
                    deserunt rerum nulla, at ratione, quis aperiam soluta atque beatae totam incidunt maxime
                    voluptatem
                    fugit libero! Molestias tempore illo assumenda repellat atque commodi sint nam illum voluptate a
                    perspiciatis, delectus ex nesciunt harum! Lorem ipsum dolor sit amet consectetur adipisicing
                    elit.
                    Vero deleniti quibusdam ex voluptas. Possimus autem facere natus a voluptas ad ipsum beatae
                    expedita, nemo nisi vero impedit eveniet nulla. Voluptates?
                </span>
            </CDBCollapse>
        </div>
    </div>
</section>