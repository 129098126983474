<div class="blockcode pt-5">
    <div class="header">Checkbox</div>
    <div class="description">Default Checkbox</div>
    <div class="example">
        <CDBInput type="checkbox"></CDBInput>
    </div>
</div>
<div class="blockcode">
    <div class="header">Checkbox <CDBBadge size="large" style="background-color:#9871ff">Pro Component
        </CDBBadge>
    </div>
    <div class="description">CDB Checkbox</div>
    <div class="example">
        <CDBCheckbox class="mt-5"></CDBCheckbox>
    </div>
</div>
<div class="blockcode">
    <div class="header">Checkbox</div>
    <div class="description">Disabled Checkbox</div>
    <div class="example">
        <CDBCheckbox disabled=true></CDBCheckbox>
    </div>
</div>