<section id="box">
    <div class="blockcode w-100 pt-5">
        <div class="header">Basic Box Examples</div>
        <div class="description">Basic Box</div>
        <div class="border p-3">
            <CDBBox tag="span">
                Span
            </CDBBox>
            <CDBBox tag="div">
                Div
            </CDBBox>
            <CDBBox tag="p">
                Paragraph
            </CDBBox>
            <CDBBox tag="section">
                Section
            </CDBBox>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Display</div>
        <div class="description">Display Box Examples</div>
        <div class="border p-3">
            <CDBBox display="flex">
                Display Flex
            </CDBBox>
            <CDBBox display="block">
                Display Block
            </CDBBox>
            <CDBBox display="none">
                Dislpay None
            </CDBBox>
            <CDBBox display="inline">
                Display Inline
            </CDBBox>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Flex</div>
        <div class="description">Flex Box Examples</div>
        <div class="border p-3">
            <CDBBox display="flex" justifyContent="center">
                Justify Content Center
            </CDBBox>
            <CDBBox display="flex" justifyContent="end">
                Justify Content End
            </CDBBox>
            <CDBBox display="flex" flex="column">
                <CDBBox tag="p">Column</CDBBox>
                <CDBBox tag="p">Column</CDBBox>
                <CDBBox tag="p">Column</CDBBox>
            </CDBBox>
            <CDBBox display="flex" flex="row">
                <CDBBox tag="p">Row</CDBBox>
                <CDBBox tag="p">Row</CDBBox>
                <CDBBox tag="p">Row</CDBBox>
            </CDBBox>
            <CDBBox display="none">
                <CDBBox display="flex" flex="fill">
                    <CDBBox tag="p">Fill</CDBBox>
                </CDBBox>
                <CDBBox display="flex" flex="row" alignItems="start">
                    Align Items Start
                </CDBBox>
                <CDBBox display="flex" flex="row" alignItems="center">
                    Align Items Center
                </CDBBox>
                <CDBBox display="flex" alignContent="start">
                    Align Content Start
                </CDBBox>
                <CDBBox display="flex" alignContent="center">
                    Align Content Center
                </CDBBox>
                <CDBBox display="flex" alignSelf="start">
                    Align Self Start
                </CDBBox>
                <CDBBox display="flex" alignSelf="center">
                    Align Self Center
                </CDBBox>
            </CDBBox>
        </div>
        <div class="blockcode w-100">
            <div class="header">Colors</div>
            <div class="description">Color Box Examples</div>
            <div class="border p-3">
                <CDBBox color="red">
                    Red Color
                </CDBBox>
                <CDBBox color="green">
                    Green Color
                </CDBBox>
                <CDBBox color="blue">
                    Blue Color
                </CDBBox>
                <CDBBox color="pink">
                    Pink Color
                </CDBBox>
            </div>
        </div>
        <div class="blockcode w-100">
            <div class="header">Background Colors</div>
            <div class="description">Background Color Box Examples</div>
            <div class="border p-3">
                <CDBBox bg="success">
                    Success Background
                </CDBBox>
                <CDBBox bg="secondary">
                    Secondary Background
                </CDBBox>
                <CDBBox bg="danger">
                    Danger Background
                </CDBBox>
                <CDBBox bg="warning">
                    Warning Background
                </CDBBox>
            </div>
        </div>
    </div>
</section>