<!-- <div style="height: 100vh">
    <div style="height: 100%"> -->
<CDBSidebar textColor="#ffffff" backgroundColor="#333333" style="position: fixed;">
    <CDBSidebarHeader (isToggled)="toggledState($event)" [prefix]="icon">
        <ng-template #icon>
            <CDBIcon icon="bars" size="lg"></CDBIcon>
        </ng-template>

        Contrast
    </CDBSidebarHeader>

    <CDBSidebarContent>
        <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large" link="/accordion">
                Accordion
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="user-clock" link="/alert">
                Alert
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="radiation" [spin]=true link="/animation">
                Animation
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="magic" link="/autocomplete">
                Autocomplete
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="certificate" link="/badge">
                Badge
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="box" link="/box">
                Box
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="route" link="/breadcrumb">
                Breadcrumb
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="mouse" link="/button">
                Button
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="mouse" link="/button-group">
                Button Group
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="mouse" link="/button-toolbar">
                Button Toolbar
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="id-card" link="/card">
                Card
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="sliders-h" link="/carousel">
                Carousel
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="check" link="/checkbox">
                Checkbox
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="sliders-h" link="/collapse">
                Collapse
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="table" link="/datatable">
                Data Table
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="calendar-day" link="/date-picker">
                Date Picker
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="caret-down" link="/dropdown">
                Dropdown
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="table" link="/editable-table">
                Editable Table
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="shoe-prints" link="/footer">
                Footer
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="sign-in-alt" link="/forms">
                Forms
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="icons" link="/icon">
                Icon
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="crop" link="/iframe">
                Iframe
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="keyboard" link="/input">
                Input
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="keyboard" link="/input-group">
                Input Group
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="list" link="/list-group">
                List Group
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="mask" link="/mask">
                Mask
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="medal" link="/modal">
                Modal
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="object-group" link="/multiselect">
                Multiselect
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="directions" link="/navbar">
                Navbar
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="flag-checkered" link="/notification">
                Notification
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="map" link="/pagination">
                Pagination
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="columns" link="/pane">
                Pane
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="columns" link="/panel">
                Panel
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="spinner" link="/progress">
                Progress
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="spinner" link="/circular-progress">
                Circular Progress
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="dot-circle" link="/radio">
                Radio
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="star-half-alt" link="/rating">
                Rating
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="mouse-pointer" link="/select">
                Select
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="hand-pointer" link="/select2">
                Select2
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="columns" link="/sidebar">
                Sidebar
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="sliders-h" link="/slider">
                Slider
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="spinner" [spin]=true link="/spinner">
                Spinner
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="step-forward" link="/stepper">
                Stepper
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="toggle-on" link="/switch">
                Switch
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="columns" link="/table">
                Table
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="indent" link="/tabs">
                Tabs
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="stopwatch" link="/time-picker">
                Time Picker
            </CDBSidebarMenuItem>
        </CDBSidebarMenu>
    </CDBSidebarContent>

    <div class="mt-auto">
        <CDBSidebarFooter style="text-align: center">
            <div class="sidebar-btn-wrapper" style="padding: 20px 5px">
                Sidebar Footer
            </div>
        </CDBSidebarFooter>
    </div>
</CDBSidebar>
<!-- </div>
</div> -->