<section id="button">
  <div class="blockcode pt-5">
    <div class="header">Basic Styles</div>
    <div class="description">Basic contrast sytles for buttons</div>
    <div class="example">
      <CDBBtn color="primary">Primary</CDBBtn>
      <CDBBtn color="secondary">Secondary</CDBBtn>
      <CDBBtn color="success">Success</CDBBtn>
      <CDBBtn color="danger">Danger</CDBBtn>
      <CDBBtn color="dark">Dark</CDBBtn>
      <CDBBtn color="warning">Warning</CDBBtn>
      <CDBBtn color="info">Info</CDBBtn>
      <CDBBtn color="white">White</CDBBtn>
      <CDBBtn color="light">Light</CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Circular Buttons</div>
    <div class="description">Circular Buttons</div>
    <div class="example">
      <CDBBtn color="primary" [circle]="true"> Primary </CDBBtn>
      <CDBBtn color="secondary" [circle]="true"> Secondary </CDBBtn>
      <CDBBtn color="success" [circle]="true"> Success </CDBBtn>
      <CDBBtn color="danger" [circle]="true"> Danger </CDBBtn>
      <CDBBtn color="dark" [circle]="true"> Dark </CDBBtn>
      <CDBBtn color="warning" [circle]="true"> Warning </CDBBtn>
      <CDBBtn color="info" [circle]="true"> Info </CDBBtn>
      <CDBBtn color="white" [circle]="true"> White </CDBBtn>
      <CDBBtn color="light" [circle]="true"> Light </CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Outline Buttons</div>
    <div class="description">Outline Buttons</div>
    <div class="example">
      <CDBBtn color="primary" [outline]="true"> Primary </CDBBtn>
      <CDBBtn color="secondary" [outline]="true"> Secondary </CDBBtn>
      <CDBBtn color="success" [outline]="true"> Success </CDBBtn>
      <CDBBtn color="danger" [outline]="true"> Danger </CDBBtn>
      <CDBBtn color="dark" [outline]="true"> Dark </CDBBtn>
      <CDBBtn color="warning" [outline]="true"> Warning </CDBBtn>
      <CDBBtn color="info" [outline]="true"> Info </CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Rounded Outline Buttons</div>
    <div class="description">Rounded Outline Buttons</div>
    <div class="example">
      <CDBBtn color="primary" [circle]="true" [outline]="true">
        Primary
      </CDBBtn>
      <CDBBtn color="secondary" [circle]="true" [outline]="true">
        Secondary
      </CDBBtn>
      <CDBBtn color="success" [circle]="true" [outline]="true">
        Success
      </CDBBtn>
      <CDBBtn color="danger" [circle]="true" [outline]="true"> Danger </CDBBtn>
      <CDBBtn color="dark" [circle]="true" [outline]="true"> Dark </CDBBtn>
      <CDBBtn color="warning" [circle]="true" [outline]="true">
        Warning
      </CDBBtn>
      <CDBBtn color="info" [circle]="true" [outline]="true"> Info </CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Flat Buttons</div>
    <div class="description">Flat Buttons</div>
    <div class="example">
      <CDBBtn color="primary" [flat]="true"> Primary </CDBBtn>
      <CDBBtn color="secondary" [flat]="true"> Secondary </CDBBtn>
      <CDBBtn color="success" [flat]="true"> Success </CDBBtn>
      <CDBBtn color="danger" [flat]="true"> Danger </CDBBtn>
      <CDBBtn color="dark" [flat]="true"> Dark </CDBBtn>
      <CDBBtn color="warning" [flat]="true"> Warning </CDBBtn>
      <CDBBtn color="info" [flat]="true"> Info </CDBBtn>
      <CDBBtn color="white" [flat]="true"> White </CDBBtn>
      <CDBBtn color="light" [flat]="true"> Light </CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Block Buttons</div>
    <div class="description">Block Buttons</div>
    <div class="example">
      <CDBBtn color="primary" [block]="true"> Primary </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="secondary" [block]="true"> Secondary </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="success" [block]="true"> Success </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="danger" [block]="true"> Danger </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="dark" [block]="true"> Dark </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="info" [block]="true"> Info </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="warning" [block]="true"> Warning </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="white" [block]="true"> White </CDBBtn>
    </div>
    <div class="example">
      <CDBBtn color="light" [block]="true"> Light </CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Buttons Sizes</div>
    <div class="description">Buttons Sizes</div>
    <div class="example">
      <CDBBtn color="primary" size="xl">Extra Large button</CDBBtn>
      <CDBBtn color="secondary" size="large">Large button</CDBBtn>
      <CDBBtn color="warning">Normal button</CDBBtn>
      <CDBBtn color="success" size="small">Small button</CDBBtn>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">Disabled Button</div>
    <div class="description">Disabled Buttons</div>
    <div class="example">
      <CDBBtn color="secondary" [disabled]="true"> Disabled Button </CDBBtn>
    </div>
  </div>
</section>
