<div class="blockcode pt-5">
    <div class="header">Carousel</div>
    <CDBCarousel activeItem='1' length='3' [showControls]=true [showIndicators]=true class="z-depth-1" [slide]=true>
        <CDBCarousel-inner>
            <CDBCarousel-item itemId="1">
                <CDBView>
                    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(45).jpg"
                        alt="First slide" />
                </CDBView>
            </CDBCarousel-item>
            <CDBCarousel-item itemId="2">
                <CDBView>
                    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(46).jpg"
                        alt="Second slide" />
                </CDBView>
            </CDBCarousel-item>
            <CDBCarousel-item itemId="3">
                <CDBView>
                    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(47).jpg"
                        alt="Third slide" />
                </CDBView>
            </CDBCarousel-item>
        </CDBCarousel-inner>
    </CDBCarousel>
</div>