<section id="ratings">
  <div class="blockcode pt-5">
    <div class="header">Rating</div>
    <div class="example flex-column">
      <CDBRating [iconRegular]="true"></CDBRating>
      <CDBRating
        [iconFaces]="true"
        fillClass="black-text"
        [iconRegular]="true"
      ></CDBRating>
      <CDBRating [feedback]="true"></CDBRating>
    </div>
  </div>
</section>
