<div class="blockcode pt-5">
    <div class="header">Circular Progress color variations
        <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
    </div>
    <div class="description">Circular Progress color variations</div>
    <div class="example">
        <CDBCircularProgress></CDBCircularProgress>
        <CDBCircularProgress value=50 max=100 min=0 color="primary" text="50%"></CDBCircularProgress>
        <CDBCircularProgress value=95 max=100 min=0 color="secondary" text="95%"></CDBCircularProgress>
        <CDBCircularProgress value=35 max=100 min=0 color="danger" text="35%"></CDBCircularProgress>
        <CDBCircularProgress value=47 max=100 min=0 color="info" text="47%"></CDBCircularProgress>
        <CDBCircularProgress value=100 max=100 min=0 color="success" text="100%"></CDBCircularProgress>
    </div>
</div>
<div class="blockcode">
    <div class="header"> Circular Progress Size variations
        <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
    </div>
    <div class="description">Circular Progress Size variations</div>
    <div class="example">
        <CDBCircularProgress value=25 max=100 min=0 text="25%" size="lg"></CDBCircularProgress>
        <CDBCircularProgress value=50 max=100 min=0 text="50%" size="sm"></CDBCircularProgress>
        <CDBCircularProgress value=95 max=100 min=0 text="95%" size="md"></CDBCircularProgress>
    </div>
</div>