<section id="multiselect">
  <div class="blockcode pt-5">
    <div class="header">
      Multiselect Styles
      <CDBBadge style="background-color: #9871ff" size="large"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Basic Multiselect</div>
    <div class="example">
      <CDBMultiselect [options]="option" selected="Value"></CDBMultiselect>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">
      Multiselect With Search
      <CDBBadge style="background-color: #9871ff" size="large"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Multiselect With Search</div>
    <div class="example">
      <CDBMultiselect
        color="secondary"
        [search]="true"
        [options]="option"
        selected="Value"
      ></CDBMultiselect>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">
      Multiselect With Submit Option
      <CDBBadge style="background-color: #9871ff" size="large"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Multiselect With Submit Option</div>
    <div class="example">
      <CDBMultiselect
        color="dark"
        [submit]="true"
        [options]="selectOptions"
        selected="Value"
      ></CDBMultiselect>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">
      Multiselect with Limited options
      <CDBBadge style="background-color: #9871ff" size="large"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Multiselect with Limited options</div>
    <div class="example">
      <CDBMultiselect
        color="danger"
        [limitedOptions]="true"
        [options]="selectOptions"
        selected="Value"
      ></CDBMultiselect>
    </div>
  </div>
</section>
