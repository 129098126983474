import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  active = 1;
  active2 = 1;

  handleNextPrevClick(stepper, a) {
    if (stepper === 1) {
      this.active = a;
    } else if (stepper === 2) {
      this.active2 = a;
    }
  }

  handleSubmission = () => {
    alert('Form submitted!');
  };

  constructor() {}

  ngOnInit(): void {}
}
