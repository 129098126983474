<CDBCard>
    <CDBCardBody>
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto pl-0">
                    <label>
                        Show Entries
                        <select class='custom-select custom-select-sm form-control form-control-sm ml-0'
                            style="margin-left: .5rem" (change)='changeEntries($event)'>
                            <option *ngFor='let entry of entries' [value]="entry">
                                {{ entry }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="col-md-6 mx-auto pl-0 pr-0 mb-1">
                    <div class="md-form">
                        <input type="text" placeholder="Search" class="form-control" [(ngModel)]="searchText"
                            (keyup)="searchItems()" id="search-input">
                    </div>
                </div>
                <table CdbTable CdbTableScroll maxHeight="500" #tableEl1="CdbTable" stickyHeader="true" hover="true"
                    striped='true' bordered="true" class="z-depth-1 col-md-12">
                    <thead class="sticky-top">
                        <tr>
                            <th *ngFor="let head of headElements; let i = index" (sortEnd)="sort()"
                                [CdbTableSort]="elements" [tableEl]='tableEl1' [enableSort]='headElements[i].enableSort'
                                [sortBy]="headElements[i].field" scope="col">{{head.label | titlecase}} <CDBIcon
                                    [fas]=true icon="sort"></CDBIcon>
                            </th>
                        </tr>
                    </thead>
                    <tbody #row>
                        <tr *ngFor="let el of pages[activePage]; let i = index">
                            <th scope="row">{{el.name}}</th>
                            <td class="red-text">{{el.position}}</td>
                            <td>{{el.office}}</td>
                            <td>{{el.age}}</td>
                            <td>{{el.date}}</td>
                            <td>{{el.salary | currency:'USD' }}</td>
                        </tr>
                        <tr *ngIf="!pages[activePage]">
                            <td scope="row" colspan="100%">No Matching Records Found</td>
                        </tr>
                    </tbody>
                    <thead class="sticky-top">
                        <tr>
                            <th *ngFor="let head of headElements; let i = index" (sortEnd)="sort()"
                                [CdbTableSort]="elements" [tableEl]='tableEl1' [enableSort]='headElements[i].enableSort'
                                [sortBy]="headElements[i].field" scope="col">{{head.label | titlecase}} <CDBIcon
                                    [fas]=true icon="sort"></CDBIcon>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div class="mt-2">
                    Showing {{activePage > 0 ? (activePage * pages[0].length) + 1 : activePage + 1}} to {{pages.length -
                    1 > activePage
                    ? pages[activePage].length * (activePage + 1)
                    : elements.length}} of {{elements.length}} records
                </div>
                <CDBTable-pagination class="ml-auto pr-0 mt-2" [tableEl]='tableEl1'></CDBTable-pagination>
            </div>
        </div>
    </CDBCardBody>
</CDBCard>