<section id="input-group">
  <div style="margin: 0 auto">
    <div class="blockcode pt-5">
      <div class="header">Basic Input Group</div>
      <div class="description">Basic Input with appended text after</div>
      <div class="example d-block">
        <CDBInputGroup
          hint="Recipient's username"
          inputGroupClass="mb-3 mt-0"
          append="@example.com"
        >
        </CDBInputGroup>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Basic Input Group</div>
      <div class="description">Basic Input with appended text before</div>
      <div class="example d-block">
        <CDBInputGroup
          inputGroupClass="flex-nowrap mb-3"
          prepend="@"
          hint="Username"
        ></CDBInputGroup>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Input Group Icon</div>
      <div class="description">Run Function on icon click</div>
      <div class="example d-block">
        <CDBInputGroup
          icon="bell"
          onIconClick=""
          append="Bells"
        ></CDBInputGroup>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Input Sizes</div>
      <div class="description">Small Input Group</div>
      <div class="example d-block">
        <CDBInputGroup
          inputGroupClass="mb-2 mt-0"
          prepend="Small"
          size="sm"
        ></CDBInputGroup>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Input Sizes</div>
      <div class="description">Large Input Group</div>
      <div class="example d-block">
        <CDBInputGroup
          inputGroupClass="mb-2 mt-0"
          prepend="Large"
          size="lg"
        ></CDBInputGroup>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Input Group</div>
      <div class="description">Input Group with Checkbox</div>
      <div class="example d-block">
        <CDBInputGroup [prepend]="example.elementRef.nativeElement">
        </CDBInputGroup>
        <ng-template #example>
          <div class="input-group-text md-addon">
            <CDBInput type="checkbox"></CDBInput>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="blockcode">
      <div class="header">Input Group</div>
      <div class="description">Basic Input</div>
      <div class="example d-block">
        <CDBInputGroup></CDBInputGroup>
      </div>
    </div>
  </div>
</section>
