<div class="blockcode pt-5">
    <div class="header">Animation Types</div>
    <div class="description"></div>
    <div class="example2">
        <CDBAnimation type="bounce" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="flash" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="pulse" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="rubberBand" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="shake" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="headShake" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="swing" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="tada" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="wobble" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="jello" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
        <CDBAnimation type="heartBeat" [infinite]=true>
            <img alt="" src="https://pngimg.com/uploads/football/football_PNG52775.png" width="50" height="50" />
        </CDBAnimation>
    </div>
</div>