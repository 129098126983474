import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Output() isToggled: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
  }

  toggledState(event) {
    this.isToggled.emit(event);
  }
}
