<section id="stepper">
  <div class="blockcode pt-5">
    <div class="header">
      Basic Vertical Stepper
      <CDBBadge size="large" style="background-color: #9871ff"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Basic Vertical Stepper</div>
    <div class="" style="display: flex; flex-direction: row">
      <div class="" style="height: 500px; width: 50px">
        <CDBStepper direction="vertical">
          <CDBStep
            [id]="1"
            [far]="true"
            [active]="active"
            icon="folder-open"
            (onClick)="handleNextPrevClick(1, 1)"
            name="Basic Information"
          ></CDBStep>
          <CDBStep
            [id]="2"
            icon="pencil-alt"
            [active]="active"
            name="Personal Data"
            (onClick)="handleNextPrevClick(1, 2)"
          >
          </CDBStep>
          <CDBStep
            [id]="3"
            icon="dragon"
            [active]="active"
            name="Terms and Conditions"
            (onClick)="handleNextPrevClick(1, 3)"
          ></CDBStep>
          <CDBStep
            [id]="4"
            icon="check"
            [active]="active"
            name="Finish"
            (onClick)="handleNextPrevClick(1, 4)"
          >
          </CDBStep>
        </CDBStepper>
      </div>
      <div
        class="container"
        style="height: 500px; width: 100%; display: flex; align-items: center"
      >
        <div *ngIf="active === 1" md="12" class="container">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Your Information
          </h3>
          <CDBInput label="Email" class="mt-4"></CDBInput>
          <CDBInput label="username" class="mt-4"></CDBInput>
          <CDBInput label="Password" class="mt-4"></CDBInput>
          <CDBInput label="Repeat Password" class="mt-4"></CDBInput>
          <CDBBtn
            color="dark"
            [block]="true"
            [flat]="true"
            class="float-right"
            (click)="handleNextPrevClick(1, 2)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active === 2" md="12" class="container">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Personal Data
          </h3>
          <CDBInput label="First Name" class="mt-3"></CDBInput>
          <CDBInput label="Second Name" class="mt-3"></CDBInput>
          <CDBInput label="Surname" class="mt-3"></CDBInput>
          <CDBInput label="Address" type="textarea" rows="2"></CDBInput>
          <CDBBtn
            color="light"
            [flat]="true"
            class="float-left"
            (click)="handleNextPrevClick(1, 1)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="dark"
            [flat]="true"
            class="float-right"
            (click)="handleNextPrevClick(1, 3)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active === 3" md="12" class="container">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Terms and conditions
          </h3>
          <CDBInput
            label="I agreee to the terms and conditions"
            type="checkbox"
            id="checkbox3"
          ></CDBInput>
          <CDBInput
            label="I want to receive newsletter"
            type="checkbox"
            id="checkbox4"
          ></CDBInput>
          <CDBBtn
            color="light"
            class="float-left"
            [flat]="true"
            (click)="handleNextPrevClick(1, 2)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="dark"
            class="float-right"
            [flat]="true"
            (click)="handleNextPrevClick(1, 4)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active === 4" md="12" class="container">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Finish
          </h3>
          <h2 class="text-center font-weight-bold my-4">
            Registration completed!
          </h2>
          <CDBBtn
            color="light"
            [flat]="true"
            class="float-left"
            (click)="handleNextPrevClick(1, 3)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="success"
            [flat]="true"
            class="float-right"
            (click)="handleSubmission()"
          >
            submit
          </CDBBtn>
        </div>
      </div>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">
      Basic Horizontal Stepper
      <CDBBadge size="large" style="background-color: #9871ff"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Basic Horizontal Stepper</div>
    <div class="container" style="display: flex; flex-direction: column">
      <div class="container" style="width: 100%">
        <CDBStepper style="width: 100%" direction="horizontal">
          <CDBStep
            [id]="1"
            [far]="true"
            icon="folder-open"
            name="Basic Information"
            (onClick)="handleNextPrevClick(2, 1)"
            [active]="active2"
          ></CDBStep>
          <CDBStep
            [id]="2"
            icon="pencil-alt"
            name="Personal Data"
            (onClick)="handleNextPrevClick(2, 2)"
            [active]="active2"
          >
          </CDBStep>
          <CDBStep
            [id]="3"
            icon="dragon"
            name="Terms and Conditions"
            (onClick)="handleNextPrevClick(2, 3)"
            [active]="active2"
          ></CDBStep>
          <CDBStep
            [id]="4"
            icon="check"
            name="Finish"
            (onClick)="handleNextPrevClick(2, 4)"
            [active]="active2"
          >
          </CDBStep>
        </CDBStepper>
      </div>
      <div
        class="container"
        style="height: 500px; width: 100%; display: flex; align-items: center"
      >
        <div *ngIf="active2 === 1" class="container" md="12">
          <h3
            class="font-weight-bold pl-0 my-4 d-flex justify-content-center"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Your Information
          </h3>
          <CDBInput label="Email" class="mt-4"></CDBInput>
          <CDBInput label="username" class="mt-4"></CDBInput>
          <CDBInput label="Password" class="mt-4"></CDBInput>
          <CDBInput label="Repeat Password" class="mt-4"></CDBInput>
          <CDBBtn
            color="dark"
            [block]="true"
            [flat]="true"
            class="float-right"
            (click)="handleNextPrevClick(2, 2)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active2 === 2" class="container" md="12">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Personal Data
          </h3>
          <CDBInput label="First Name" class="mt-3"></CDBInput>
          <CDBInput label="Second Name" class="mt-3"></CDBInput>
          <CDBInput label="Surname" class="mt-3"></CDBInput>
          <CDBInput label="Address" type="textarea" rows="2"></CDBInput>
          <CDBBtn
            color="light"
            [flat]="true"
            class="float-left"
            (click)="handleNextPrevClick(2, 1)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="dark"
            [flat]="true"
            class="float-right"
            (click)="handleNextPrevClick(2, 3)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active2 === 3" class="container" md="12">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Terms and conditions
          </h3>
          <CDBInput
            label="I agreee to the terms and conditions"
            type="checkbox"
            id="checkbox3"
          ></CDBInput>
          <CDBInput
            label="I want to receive newsletter"
            type="checkbox"
            id="checkbox4"
          ></CDBInput>
          <CDBBtn
            color="light"
            class="float-left"
            [flat]="true"
            (click)="handleNextPrevClick(2, 2)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="dark"
            class="float-right"
            [flat]="true"
            (click)="handleNextPrevClick(2, 4)"
          >
            Next
          </CDBBtn>
        </div>
        <div *ngIf="active2 === 4" class="container" md="12">
          <h3
            class="font-weight-bold pl-0 my-4"
            style="width: 100%; font-size: 30px; text-align: center"
          >
            Finish
          </h3>
          <h2 class="text-center font-weight-bold my-4">
            Registration completed!
          </h2>
          <CDBBtn
            color="light"
            [flat]="true"
            class="float-left"
            (click)="handleNextPrevClick(2, 3)"
          >
            Previous
          </CDBBtn>
          <CDBBtn
            color="success"
            [flat]="true"
            class="float-right"
            (click)="handleSubmission()"
          >
            submit
          </CDBBtn>
        </div>
      </div>
    </div>
  </div>
  <div class="blockcode">
    <div class="header">
      Stepper Without Icons
      <CDBBadge size="large" style="background-color: #9871ff"
        >Pro Component</CDBBadge
      >
    </div>
    <div class="description">Stepper Without Icons</div>
    <div class="container">
      <CDBStepper style="width: 100%" direction="horizontal">
        <CDBStep name="Label 1" id="{1}"></CDBStep>
        <CDBStep name="Label 2" id="{2}"></CDBStep>
        <CDBStep name="Label 3" id="{3}"></CDBStep>
        <CDBStep name="Label 4" id="{4}"></CDBStep>
      </CDBStepper>
    </div>
  </div>
</section>
