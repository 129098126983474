import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableTableComponent implements OnInit {
  columns = ['Fullname', 'Age', 'Company Name', 'City', 'Country'];

  data = [
    ['Guerra Cortez', 45, 'Insectus', 'San Francisco', 'USA'],
    ['Elisa Gallagher', 31, 'Portica', 'London', 'United Kingdom'],
    ['Aurelia Vega', 30, 'Deepends', 'Madrid', 'Spain'],
    ['Guadalupe House', 26, 'Isotronic', 'Berlin', 'Germany'],
  ];

  constructor() {}

  ngOnInit(): void {}
}
