import {
  Component,
  Renderer2,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('main', { static: false }) main: ElementRef;

  constructor(private renderer: Renderer2) {}

  toggledState(event) {
    setTimeout(() => {
      if (event === true) {
        this.renderer.setStyle(
          this.main.nativeElement,
          'width',
          'calc(100% - 80px)'
        );
      } else {
        this.renderer.setStyle(
          this.main.nativeElement,
          'width',
          'calc(100% - 275px)'
        );
      }
    }, 100);
  }

  ngAfterViewInit() {
  }
}
