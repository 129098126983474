<section id="navbar">
  <div class="blockcode pt-5">
    <div class="header">Basic Example</div>
    <div class="description"></div>
    <div class="container-fluid">
      <header>
        <CDBNavbar
          style="background-color: black"
          [dark]="true"
          expand="lg"
          [scrolling]="true"
        >
          <CDBNavBrand href="/">
            <strong>Navbar</strong>
          </CDBNavBrand>
          <CDBNavToggle (click)="myNavbar.toggleCollapse()"></CDBNavToggle>
          <CDBCollapse #myNavbar class="w-100" [navbar]="true" expand="lg">
            <CDBNavbarNav [left]="true">
              <CDBNavItem>
                <CDBNavLink to="/accordion" style="color: #ffffff">
                  Accordion
                </CDBNavLink>
              </CDBNavItem>
              <CDBNavItem>
                <CDBNavLink
                  href="https://www.google.com"
                  style="color: #ffffff"
                >
                  External Page
                </CDBNavLink>
              </CDBNavItem>
              <CDBNavItem>
                <CDBNavLink to="/" style="color: #ffffff"> </CDBNavLink>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav [right]="true" class="align-items-center">
              <CDBNavItem [active]="true">
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="/" style="color: #ffffff"> </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="#" style="color: #ffffff"> </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="#" style="color: #ffffff">
                    Pricing
                  </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBDropDown>
                  <CDBDropDownToggle
                    [caretDropDown]="true"
                    style="padding: 0"
                    color="dark"
                    (click)="exampleMenu.toggleDropdown($event)"
                  >
                    Options
                  </CDBDropDownToggle>
                  <CDBDropDownMenu
                    #exampleMenu="cdbDropdownMenu"
                    placement="bottom"
                  >
                    Coming soon #pleaseStayUpdated.
                  </CDBDropDownMenu>
                </CDBDropDown>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
    </div>
  </div>

  <div class="blockcode">
    <div class="header">Example2</div>
    <div class="description"></div>
    <div class="container-fluid">
      <header>
        <CDBNavbar
          style="background: black; color: #f4f4f4"
          [dark]="true"
          expand="lg"
          [scrolling]="true"
        >
          <CDBNavBrand>
            <strong>Navbar</strong>
          </CDBNavBrand>
          <CDBNavToggle
            (click)="myNavbar2.toggleCollapse()"
            image="https://media.istockphoto.com/vectors/cartoon-with-contour-of-hamburger-with-cheese-tomato-and-salad-vector-id843213568"
          >
          </CDBNavToggle>
          <CDBCollapse #myNavbar2 class="w-100" [navbar]="true" expand="lg">
            <CDBNavbarNav [left]="true" class="align-items-center">
              <CDBNavItem>
                <CDBDropDown>
                  <CDBDropDownToggle
                    [caretDropDown]="true"
                    style="padding: 0"
                    color="dark"
                    (click)="exampleMenu2.toggleDropdown($event)"
                  >
                    Categories
                  </CDBDropDownToggle>
                  <CDBDropDownMenu
                    #exampleMenu2="cdbDropdownMenu"
                    placement="bottom"
                  >
                    Coming soon #pleaseStayUpdated.
                  </CDBDropDownMenu>
                </CDBDropDown>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="/" style="color: #ffffff">Help</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="/" style="color: #ffffff">About</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav [right]="true">
              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="/" style="color: #ffffff"> EN </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>

              <CDBNavItem>
                <CDBBtn [flat]="true" color="dark" style="padding: 0">
                  <CDBNavLink to="/" style="color: #ffffff"> Login </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn color="white" style="padding: 0">
                  <CDBNavLink to="/" style="color: #000000">
                    Sign Up
                  </CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
    </div>
  </div>

  <!-- <CDBNavbar style="background: black; color: #f4f4f4" [dark]=true expand="lg">
        <CDBNavBrand href="/">
            <img src="https://ipullrank.com/wp-content/uploads/2020/07/google-logo-png-google-logo-icon-png-transparent-background-1000.png"
                alt="Brand" class="img-fluid" width="30">
        </CDBNavBrand>
        <CDBNavToggle (click)="myNav.toggleCollapse()"></CDBNavToggle>
        <CDBCollapse #myNav class="w-100" [navbar]=true expand="lg">
            <CDBNavbarNav [left]=true class="align-items-center">
                <CDBNavItem>
                    <CDBDropDown>
                        <CDBDropDownToggle [caretDropDown]=true style="padding: 0" color="dark"
                            (click)="menu.toggleDropdown($event)">
                            Categories
                        </CDBDropDownToggle>
                        <CDBDropDownMenu #menu="cdbDropdownMenu" placement="bottom">
                            Coming soon #pleaseStayUpdated.
                        </CDBDropDownMenu>
                    </CDBDropDown>
                </CDBNavItem>
                <CDBNavItem>
                    <CDBBtn [flat]=true color="dark" style="padding: 0">
                        <CDBNavLink to="/" style="color: #ffffff">Help</CDBNavLink>
                    </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                    <CDBBtn [flat]=true color="dark" style="padding: 0">
                        <CDBNavLink to="/" style="color: #ffffff">About</CDBNavLink>
                    </CDBBtn>
                </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav [right]=true>
                <CDBNavItem>
                    <CDBBtn [flat]=true color="dark" style="padding: 0">
                        <CDBNavLink to="/" style="color: #ffffff">
                            EN
                        </CDBNavLink>
                    </CDBBtn>
                </CDBNavItem>
    
                <CDBNavItem>
                    <CDBBtn [flat]=true color="dark" style="padding: 0">
                        <CDBNavLink to="/" style="color: #ffffff">
                            Login
                        </CDBNavLink>
                    </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                    <CDBBtn color="white" style="padding: 0">
                        <CDBNavLink to="/" style="color: #000000;">
                            Sign Up
                        </CDBNavLink>
                    </CDBBtn>
                </CDBNavItem>
            </CDBNavbarNav>
        </CDBCollapse>
    </CDBNavbar> -->

  <div class="mt-5">
    <CDBNavbar
      style="background: black; color: #f4f4f4"
      [dark]="true"
      expand="lg"
    >
      <CDBNavBrand href="/">
        <img
          src="https://ipullrank.com/wp-content/uploads/2020/07/google-logo-png-google-logo-icon-png-transparent-background-1000.png"
          alt="Brand"
          class="img-fluid"
          width="30"
        />
      </CDBNavBrand>
      <CDBNavToggle (click)="myNav.toggleCollapse()"></CDBNavToggle>
      <CDBCollapse #myNav class="w-100" [navbar]="true" expand="lg">
        <CDBNavbarNav [left]="true" class="align-items-center">
          <CDBNavItem>
            <CDBDropDown>
              <CDBDropDownToggle
                [caretDropDown]="true"
                style="padding: 0"
                color="dark"
                (click)="menu.toggleDropdown($event)"
              >
                Categories
              </CDBDropDownToggle>
              <CDBDropDownMenu #menu="cdbDropdownMenu" placement="bottom">
                Coming soon #pleaseStayUpdated.
              </CDBDropDownMenu>
            </CDBDropDown>
          </CDBNavItem>
          <CDBNavItem>
            <CDBBtn [flat]="true" color="dark" style="padding: 0">
              <CDBNavLink to="/" style="color: #ffffff">Help</CDBNavLink>
            </CDBBtn>
          </CDBNavItem>
          <CDBNavItem>
            <CDBBtn [flat]="true" color="dark" style="padding: 0">
              <CDBNavLink to="/" style="color: #ffffff">About</CDBNavLink>
            </CDBBtn>
          </CDBNavItem>
        </CDBNavbarNav>
        <CDBNavbarNav [right]="true">
          <CDBNavItem>
            <CDBBtn [flat]="true" color="dark" style="padding: 0">
              <CDBNavLink to="/" style="color: #ffffff"> EN </CDBNavLink>
            </CDBBtn>
          </CDBNavItem>

          <CDBNavItem>
            <CDBBtn [flat]="true" color="dark" style="padding: 0">
              <CDBNavLink to="/" style="color: #ffffff"> Login </CDBNavLink>
            </CDBBtn>
          </CDBNavItem>
          <CDBNavItem>
            <CDBBtn color="white" style="padding: 0">
              <CDBNavLink to="/" style="color: #000000"> Sign Up </CDBNavLink>
            </CDBBtn>
          </CDBNavItem>
        </CDBNavbarNav>
      </CDBCollapse>
    </CDBNavbar>
  </div>
</section>
