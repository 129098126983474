import { Component, OnInit, ViewChild } from '@angular/core';
import { CdbTableDirective } from 'cdbangular-pro';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit {
  @ViewChild(CdbTableDirective, { static: true }) CdbTable: CdbTableDirective;
  
  elements: any = [
    {
      name: 'Tiger Nixon',
      position: 'System Architect',
      office: 'Edinburgh',
      age: '61',
      date: '2011/04/25',
      salary: '320',
    },
    {
      name: 'Garrett Winters',
      position: 'Accountant',
      office: 'Tokyo',
      age: '63',
      date: '2011/07/25',
      salary: '170',
    },
    {
      name: 'Ashton Cox',
      position: 'Junior Technical Author',
      office: 'San Francisco',
      age: '66',
      date: '2009/01/12',
      salary: '86',
    },
    {
      name: 'Cedric Kelly',
      position: 'Senior Javascript Developer',
      office: 'Edinburgh',
      age: '22',
      date: '2012/03/29',
      salary: '433',
    },
    {
      name: 'Airi Satou',
      position: 'Accountant',
      office: 'Tokyo',
      age: '33',
      date: '2008/11/28',
      salary: '162',
    },
    {
      name: 'Brielle Williamson',
      position: 'Integration Specialist',
      office: 'New York',
      age: '61',
      date: '2012/12/02',
      salary: '372',
    },
    {
      name: 'Herrod Chandler',
      position: 'Sales Assistant',
      office: 'San Francisco',
      age: '59',
      date: '2012/08/06',
      salary: '137',
    },
    {
      name: 'Rhona Davidson',
      position: 'Integration Specialist',
      office: 'Tokyo',
      age: '55',
      date: '2010/10/14',
      salary: '327',
    },
    {
      name: 'Colleen Hurst',
      position: 'Javascript Developer',
      office: 'San Francisco',
      age: '39',
      date: '2009/09/15',
      salary: '205',
    },
    {
      name: 'Sonya Frost',
      position: 'Software Engineer',
      office: 'Edinburgh',
      age: '23',
      date: '2008/12/13',
      salary: '103',
    },
    {
      name: 'Jena Gaines',
      position: 'Office Manager',
      office: 'London',
      age: '30',
      date: '2008/12/19',
      salary: '90',
    },
    {
      name: 'Quinn Flynn',
      position: 'Support Lead',
      office: 'Edinburgh',
      age: '22',
      date: '2013/03/03',
      salary: '342',
    },
    {
      name: 'Charde Marshall',
      position: 'Regional Director',
      office: 'San Francisco',
      age: '36',
      date: '2008/10/16',
      salary: '470',
    },
    {
      name: 'Haley Kennedy',
      position: 'Senior Marketing Designer',
      office: 'London',
      age: '43',
      date: '2012/12/18',
      salary: '313',
    }
  ];
  pages = [];
  activePage = 0;
  previousData;

  //Declare entries options
  entries = [5, 10, 15];
  
  //table head elements
  headElements = [
    {
      label: 'Name',
      field: 'name',
      enableSort: 'enabled',
    },
    {
      label: 'Position',
      field: 'position',
      enableSort: 'enabled',
    },
    {
      label: 'Office',
      field: 'office',
      enableSort: 'enabled',
    },
    {
      label: 'Age',
      field: 'age',
      enableSort: 'enabled',
    },
    {
      label: 'Date',
      field: 'date',
      enableSort: 'enabled',
    },
    {
      label: 'Salary',
      field: 'salary',
      enableSort: 'enabled',
    },
  ];

  searchText: string = '';

  constructor() {}

  ngOnInit(): void {
    //subscribe to the current active page
    this.CdbTable._activePage.subscribe((data) => {
      this.activePage = data;
    });

    this.CdbTable.setEntries(this.entries[0]);
    this.CdbTable.setDataSource(this.elements);
    this.CdbTable.setPages();
    this.previousData = this.CdbTable.getDataSource();
    this.pages = this.CdbTable.pages;
  }

  searchItems() {
    const prev = this.CdbTable.getDataSource();

    if (!this.searchText) {
      this.elements = this.CdbTable.getDataSource();
      this.CdbTable.setDataSource(this.previousData);
      this.CdbTable.setPages();
      this.pages = this.CdbTable.pages;
    }

    if (this.searchText) {
      this.CdbTable.setDataSource(this.previousData);
      this.elements = this.CdbTable.filterData(this.searchText);
      this.CdbTable.setDataSource(this.elements);
      this.CdbTable.setPages();
      this.pages = this.CdbTable.pages;
    }
  }

  changeEntries(e) {
    this.CdbTable.setEntries(e.target.value);
    this.CdbTable.setPages();
    this.pages = this.CdbTable.pages;
    this.CdbTable._activePage.next(0);
  }

  sort() {
    this.pages = this.CdbTable.pages;
  }
}
