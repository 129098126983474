<section id="buttongroup">
  <div class="blockcode pt-5">
    <div class="header">Multicolored Button Groups</div>
    <div class="description">Rect Secondary Large</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp size="lg">
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>

    <div class="description">Rect Secondary Medium with Space between</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp>
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
      </CDBBtnGrp>
      <CDBBtnGrp>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>

    <div class="description">Rect Secondary Small</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp size="sm">
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>
  </div>

  <div class="blockcode">
    <div class="description">Vertical Rect Secondary Large</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp [vertical]="true" size="lg">
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>

    <div class="description">Vertical Rect Secondary Medium</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp [vertical]="true">
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>

    <div class="description">Vertical Rect Secondary Small</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp [vertical]="true" size="sm">
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
        <CDBBtn color="danger">click me</CDBBtn>
        <CDBBtn color="dark">click me</CDBBtn>
        <CDBBtn color="warning">click me</CDBBtn>
      </CDBBtnGrp>
    </div>

    <div class="description">Button Group With DropDown</div>
    <div class="d-flex justify-content-center border p-5">
      <CDBBtnGrp>
        <CDBBtn color="primary">click me</CDBBtn>
        <CDBBtn color="secondary">click me</CDBBtn>
        <CDBBtn color="success">click me</CDBBtn>
      </CDBBtnGrp>
      <CDBBtnGrp class="ml-2">
        <CDBDropDown>
          <CDBDropDownToggle
            color="warning"
            [caretDropRight]="true"
            (click)="menu.toggleDropdown($event)"
            >Dropdown
          </CDBDropDownToggle>
          <CDBDropDownMenu #menu="cdbDropdownMenu" placement="right">
            <CDBDropDownItem>Action 1</CDBDropDownItem>
            <CDBDropDownItem>Action 2</CDBDropDownItem>
          </CDBDropDownMenu>
        </CDBDropDown>
      </CDBBtnGrp>
    </div>
  </div>
</section>
