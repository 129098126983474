<div
    style="margin: auto; display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh;">
    <div class="blockcode w-100 pt-5">
        <div class="header">Basic Dropdown (with Mouse Hover)</div>
        <div class="description">Dark Toggle Bar</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="dark" (click)="menu1.toggleDropdown($event)"
                    (mouseenter)="menu1.toggleDropdown($event)">
                    Dark Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu1="cdbDropdownMenu">
                    This is the toogle menu
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Dropdown</div>
        <div class="description">Primary Toggle Bar With Caret</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="primary" [caretDropDown]=true (click)="menu2.toggleDropdown($event)">
                    Primary Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu2="cdbDropdownMenu" placement="bottom">
                    This is the toogle menu with caret
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Drop right</div>
        <div class="description">Secondary ToggleBar</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle [nav]=true color="secondary" [caretDropRight]=true
                    (click)="menu3.toggleDropdown($event)">
                    Secondary Toggle Bar as nav
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu3="cdbDropdownMenu" placement="right">
                    This is the toogle menu to the right
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Drop Up</div>
        <div class="description">Danger ToggleBar</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="danger" [caretDropUp]=true (click)="menu4.toggleDropdown($event)">
                    Danger Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu4="cdbDropdownMenu" placement="top">
                    This is the toogle menu to the top
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Drop left</div>
        <div class="description">Success Toggle Bar</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="success" [caretDropLeft]=true (click)="menu5.toggleDropdown($event)">
                    Success Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu5="cdbDropdownMenu" placement="left">
                    This is the toogle menu to the bottom
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Size Variation</div>
        <div class="description">Large Dropdown</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="dark" size="lg" (click)="menu6.toggleDropdown($event)">
                    Large Dark Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu6="cdbDropdownMenu" placement="right">
                    This is the toogle menu to the right
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Size Variation</div>
        <div class="description">Small Dropdown</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="secondary" size="sm" (click)="menu7.toggleDropdown($event)">
                    Small Secondary Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu7="cdbDropdownMenu" placement="right">
                    <CDBDropDownItem [header]=true>cold place</CDBDropDownItem>
                    <CDBDropDownItem [divider]=true></CDBDropDownItem>
                    <CDBDropDownItem [disabled]=true>
                        First Item in cold place
                    </CDBDropDownItem>
                    <CDBDropDownItem [disabled]=true>second</CDBDropDownItem>
                    <CDBDropDownItem [toggle]=true>second</CDBDropDownItem>
                    <CDBDropDownItem>second</CDBDropDownItem>
                    <CDBDropDownItem>
                        <CDBLink to="/alert"> Alert</CDBLink>
                    </CDBDropDownItem>
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Disabled Dropdown</div>
        <div class="description">Danger Toggle Bar</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="danger" [disabled]=true (click)="menu8.toggleDropdown($event)">
                    Disabled Danger Toggle Bar
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu8="cdbDropdownMenu" placement="right">
                    This is the toogle menu to the right
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Dropdown Menu</div>
        <div class="description">Menu With Header</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="secondary" (click)="menu9.toggleDropdown($event)">
                    DropDown
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu9="cdbDropdownMenu" placement="right">
                    <CDBDropDownItem [header]=true>Cold place</CDBDropDownItem>
                    <CDBDropDownItem>
                        First Item in cold place
                    </CDBDropDownItem>
                    <CDBDropDownItem [toggle]=true>second</CDBDropDownItem>
                    <CDBDropDownItem>second</CDBDropDownItem>
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Dropdown Menu</div>
        <div class="description">Menu With Divider</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="warning" (click)="menu10.toggleDropdown($event)">
                    DropDown
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu10="cdbDropdownMenu" placement="left">
                    <CDBDropDownItem>
                        <CDBLink to="/alert">Alert</CDBLink>
                    </CDBDropDownItem>
                    <CDBDropDownItem>
                        <CDBLink href="https://www.google.com">Google</CDBLink>
                    </CDBDropDownItem>
                    <CDBDropDownItem [divider]=true></CDBDropDownItem>
                    <CDBDropDownItem>
                        First Item in cold place
                    </CDBDropDownItem>
                    <CDBDropDownItem [toggle]=true>second</CDBDropDownItem>
                    <CDBDropDownItem>second</CDBDropDownItem>
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Dropdown Menu</div>
        <div class="description">Disabled Menu Items</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="success" (click)="menu11.toggleDropdown($event)">
                    DropDown
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu11="cdbDropdownMenu" placement="top">
                    <CDBDropDownItem>Cold place</CDBDropDownItem>
                    <CDBDropDownItem [disabled]=true>
                        Disabled
                    </CDBDropDownItem>
                    <CDBDropDownItem [disabled]=true>Second</CDBDropDownItem>
                    <CDBDropDownItem>Last</CDBDropDownItem>
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
    <div class="blockcode w-100">
        <div class="header">Dropdown Menu</div>
        <div class="description">Disabled Menu Items</div>
        <div class="d-flex flex-column justify-content-center align-items-center border p-3">
            <CDBDropDown>
                <CDBDropDownToggle color="dark" (click)="menu12.toggleDropdown($event)">
                    DropDown
                </CDBDropDownToggle>
                <CDBDropDownMenu #menu12="cdbDropdownMenu" placement="top">
                    <CDBDropDownItem>Cold place</CDBDropDownItem>
                    <CDBDropDownItem [active]=true>
                        Active
                    </CDBDropDownItem>
                    <CDBDropDownItem [toggle]=true>Second</CDBDropDownItem>
                    <CDBDropDownItem>Last</CDBDropDownItem>
                </CDBDropDownMenu>
            </CDBDropDown>
        </div>
    </div>
</div>