import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  exampleOptions = {
    // altInput: true,
    // altFormat: 'F j, Y',
    dateFormat: 'd/m/Y',
    // minDate: '01-01-2020',
    // maxDate: '30-12-2022',
  };

  exampleOptions2 = {
    altInput: true,
    // altFormat: 'F j, Y',
    dateFormat: 'd/m/Y',
    // minDate: '01-01-2020',
    // maxDate: '30-12-2022',
  };

  constructor() {}

  ngOnInit(): void {}
}
