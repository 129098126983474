import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './containers/sidebar/sidebar.component';
import { AccordionComponent } from './containers/accordion/accordion.component';
import { AlertComponent } from './containers/alert/alert.component';
import { AnimationComponent } from './containers/animation/animation.component';
import { AutocompleteComponent } from './containers/autocomplete/autocomplete.component';
import { BadgeComponent } from './containers/badge/badge.component';
import { BoxComponent } from './containers/box/box.component';
import { BreadcrumbComponent } from './containers/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './containers/button/button.component';
import { ButtongroupComponent } from './containers/buttongroup/buttongroup.component';
import { ButtontoolbarComponent } from './containers/buttontoolbar/buttontoolbar.component';
import { CardComponent } from './containers/card/card.component';
import { CarouselComponent } from './containers/carousel/carousel.component';
import { CheckboxComponent } from './containers/checkbox/checkbox.component';
import { CollapseComponent } from './containers/collapse/collapse.component';
import { DatatableComponent } from './containers/datatable/datatable.component';
import { DatePickerComponent } from './containers/date-picker/date-picker.component';
import { DropdownComponent } from './containers/dropdown/dropdown.component';
import { EditableTableComponent } from './containers/editable-table/editable-table.component';
import { FooterComponent } from './containers/footer/footer.component';
import { FormsComponent } from './containers/forms/forms.component';
import { IconComponent } from './containers/icon/icon.component';
import { IframeComponent } from './containers/iframe/iframe.component';
import { InputComponent } from './containers/input/input.component';
import { InputgroupComponent } from './containers/inputgroup/inputgroup.component';
import { ListgroupComponent } from './containers/listgroup/listgroup.component';
import { MaskComponent } from './containers/mask/mask.component';
import { ModalComponent } from './containers/modal/modal.component';
import { MultiselectComponent } from './containers/multiselect/multiselect.component';
import { NavbarComponent } from './containers/navbar/navbar.component';
import { NotificationComponent } from './containers/notification/notification.component';
import { PaginationComponent } from './containers/pagination/pagination.component';
import { PaneComponent } from './containers/pane/pane.component';
import { PanelComponent } from './containers/panel/panel.component';
import { ProgressComponent } from './containers/progress/progress.component';
import { RadioComponent } from './containers/radio/radio.component';
import { RatingComponent } from './containers/rating/rating.component';
import { SelectComponent } from './containers/select/select.component';
import { Select2Component } from './containers/select2/select2.component';
import { SliderComponent } from './containers/slider/slider.component';
import { SpinnerComponent } from './containers/spinner/spinner.component';
import { StepperComponent } from './containers/stepper/stepper.component';
import { SwitchComponent } from './containers/switch/switch.component';
import { TableComponent } from './containers/table/table.component';
import { TabsComponent } from './containers/tabs/tabs.component';
import { TimepickerComponent } from './containers/timepicker/timepicker.component';
import { CDBProModule } from 'cdbangular-pro';
import { CircularProgressComponent } from './containers/circular-progress/circular-progress.component';
import { FormsModule } from '@angular/forms';
import { Sidebar2Component } from './containers/sidebar2/sidebar2.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    AccordionComponent,
    AlertComponent,
    AnimationComponent,
    AutocompleteComponent,
    BadgeComponent,
    BoxComponent,
    BreadcrumbComponent,
    ButtonComponent,
    ButtongroupComponent,
    ButtontoolbarComponent,
    CardComponent,
    CarouselComponent,
    CheckboxComponent,
    CollapseComponent,
    DatatableComponent,
    DatePickerComponent,
    DropdownComponent,
    EditableTableComponent,
    FooterComponent,
    FormsComponent,
    IconComponent,
    IframeComponent,
    InputComponent,
    InputgroupComponent,
    ListgroupComponent,
    MaskComponent,
    ModalComponent,
    MultiselectComponent,
    NavbarComponent,
    NotificationComponent,
    PaginationComponent,
    PaneComponent,
    PanelComponent,
    ProgressComponent,
    RadioComponent,
    RatingComponent,
    SelectComponent,
    Select2Component,
    SliderComponent,
    SpinnerComponent,
    StepperComponent,
    SwitchComponent,
    TableComponent,
    TabsComponent,
    TimepickerComponent,
    CircularProgressComponent,
    Sidebar2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    CDBProModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
