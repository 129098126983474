<section id="editable-table">
  <div class="blockcode pt-5">
    <div class="header">Editable Table</div>
    <div class="example2">
      <h2 class="header">
        Editable Table
        <CDBBadge size="large" style="background-color: #9871ff"
          >Pro Component</CDBBadge
        >
      </h2>
      <CDBCard style="overflow-x: scroll">
        <CDBCardBody>
          <CDBEditableTable
            [striped]="true"
            [bordered]="true"
            [data]="data"
            [columns]="columns"
          ></CDBEditableTable>
        </CDBCardBody>
      </CDBCard>
    </div>
  </div>
</section>
