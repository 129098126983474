import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccordionComponent } from './containers/accordion/accordion.component';
import { AlertComponent } from './containers/alert/alert.component';
import { AnimationComponent } from './containers/animation/animation.component';
import { AutocompleteComponent } from './containers/autocomplete/autocomplete.component';
import { BadgeComponent } from './containers/badge/badge.component';
import { BoxComponent } from './containers/box/box.component';
import { BreadcrumbComponent } from './containers/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './containers/button/button.component';
import { ButtongroupComponent } from './containers/buttongroup/buttongroup.component';
import { ButtontoolbarComponent } from './containers/buttontoolbar/buttontoolbar.component';
import { CardComponent } from './containers/card/card.component';
import { CarouselComponent } from './containers/carousel/carousel.component';
import { CheckboxComponent } from './containers/checkbox/checkbox.component';
import { CircularProgressComponent } from './containers/circular-progress/circular-progress.component';
import { CollapseComponent } from './containers/collapse/collapse.component';
import { DatatableComponent } from './containers/datatable/datatable.component';
import { DatePickerComponent } from './containers/date-picker/date-picker.component';
import { DropdownComponent } from './containers/dropdown/dropdown.component';
import { EditableTableComponent } from './containers/editable-table/editable-table.component';
import { FooterComponent } from './containers/footer/footer.component';
import { FormsComponent } from './containers/forms/forms.component';
import { IconComponent } from './containers/icon/icon.component';
import { IframeComponent } from './containers/iframe/iframe.component';
import { InputComponent } from './containers/input/input.component';
import { InputgroupComponent } from './containers/inputgroup/inputgroup.component';
import { ListgroupComponent } from './containers/listgroup/listgroup.component';
import { MaskComponent } from './containers/mask/mask.component';
import { ModalComponent } from './containers/modal/modal.component';
import { MultiselectComponent } from './containers/multiselect/multiselect.component';
import { NavbarComponent } from './containers/navbar/navbar.component';
import { NotificationComponent } from './containers/notification/notification.component';
import { PaginationComponent } from './containers/pagination/pagination.component';
import { PaneComponent } from './containers/pane/pane.component';
import { PanelComponent } from './containers/panel/panel.component';
import { ProgressComponent } from './containers/progress/progress.component';
import { RadioComponent } from './containers/radio/radio.component';
import { RatingComponent } from './containers/rating/rating.component';
import { SelectComponent } from './containers/select/select.component';
import { Select2Component } from './containers/select2/select2.component';
import { Sidebar2Component } from './containers/sidebar2/sidebar2.component';
import { SliderComponent } from './containers/slider/slider.component';
import { SpinnerComponent } from './containers/spinner/spinner.component';
import { StepperComponent } from './containers/stepper/stepper.component';
import { SwitchComponent } from './containers/switch/switch.component';
import { TableComponent } from './containers/table/table.component';
import { TabsComponent } from './containers/tabs/tabs.component';
import { TimepickerComponent } from './containers/timepicker/timepicker.component';

const routes: Routes = [
  { path: '', redirectTo: '/accordion', pathMatch: 'full' },
  {
    path: 'alert',
    component: AlertComponent,
  },
  {
    path: 'autocomplete',
    component: AutocompleteComponent,
  },
  {
    path: 'dropdown',
    component: DropdownComponent,
  },
  {
    path: 'date-picker',
    component: DatePickerComponent,
  },
  {
    path: 'editable-table',
    component: EditableTableComponent,
  },
  {
    path: 'multiselect',
    component: MultiselectComponent,
  },
  {
    path: 'iframe',
    component: IframeComponent,
  },
  {
    path: 'notification',
    component: NotificationComponent,
  },
  {
    path: 'pane',
    component: PaneComponent,
  },
  {
    path: 'panel',
    component: PanelComponent,
  },
  {
    path: 'radio',
    component: RadioComponent,
  },
  {
    path: 'badge',
    component: BadgeComponent,
  },
  {
    path: 'box',
    component: BoxComponent,
  },
  {
    path: 'spinner',
    component: SpinnerComponent,
  },
  {
    path: 'button',
    component: ButtonComponent,
  },
  {
    path: 'button-group',
    component: ButtongroupComponent,
  },
  {
    path: 'button-toolbar',
    component: ButtontoolbarComponent,
  },
  {
    path: 'breadcrumb',
    component: BreadcrumbComponent,
  },
  {
    path: 'list-group',
    component: ListgroupComponent,
  },
  {
    path: 'pagination',
    component: PaginationComponent,
  },
  {
    path: 'progress',
    component: ProgressComponent,
  },
  {
    path: 'circular-progress',
    component: CircularProgressComponent,
  },
  {
    path: 'animation',
    component: AnimationComponent,
  },
  {
    path: 'table',
    component: TableComponent,
  },
  {
    path: 'carousel',
    component: CarouselComponent,
  },
  {
    path: 'card',
    component: CardComponent,
  },
  {
    path: 'checkbox',
    component: CheckboxComponent,
  },
  {
    path: 'rating',
    component: RatingComponent,
  },
  {
    path: 'modal',
    component: ModalComponent,
  },
  {
    path: 'mask',
    component: MaskComponent,
  },
  {
    path: 'select',
    component: SelectComponent,
  },
  {
    path: 'select2',
    component: Select2Component,
  },
  {
    path: 'navbar',
    component: NavbarComponent,
  },
  {
    path: 'sidebar',
    component: Sidebar2Component,
  },
  {
    path: 'accordion',
    component: AccordionComponent,
  },
  {
    path: 'switch',
    component: SwitchComponent,
  },
  {
    path: 'forms',
    component: FormsComponent,
  },
  {
    path: 'icon',
    component: IconComponent,
  },
  {
    path: 'input',
    component: InputComponent,
  },
  {
    path: 'input-group',
    component: InputgroupComponent,
  },
  {
    path: 'slider',
    component: SliderComponent,
  },
  {
    path: 'footer',
    component: FooterComponent,
  },
  {
    path: 'datatable',
    component: DatatableComponent,
  },
  {
    path: 'collapse',
    component: CollapseComponent,
  },
  {
    path: 'stepper',
    component: StepperComponent,
  },
  {
    path: 'tabs',
    component: TabsComponent,
  },
  {
    path: 'time-picker',
    component: TimepickerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
