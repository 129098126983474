<section id="autocomplete">
    <div class="blockcode pt-5">
        <div class="header">Default Autocomplete
            <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
        </div>
        <div class="description"></div>
        <div class="container-fluid">
            <div class="" style="position: relative;">
                <CDBAutocomplete label="Which country are you from" [suggestions]=countryList></CDBAutocomplete>
            </div>
        </div>
    </div>
    <div class="blockcode">
        <div class="header">Usage
            <CDBBadge size="large" style="background-color: #9871ff">Pro Component</CDBBadge>
        </div>
        <div class="description"></div>
        <div class="container-fluid">
            <div class="w-100">
                <CDBCard>
                    <CDBCardBody class="mx-1">
                        <div class="text-center">
                            <h3 class="dark-grey-text mb-5">
                                <strong>Sign in</strong>
                            </h3>
                        </div>
                        <CDBInput label="Your email" [group]=true type="email" validate error="wrong" success="right">
                        </CDBInput>
                        <CDBInput label="Your password" [group]=true type="password" validate containerClass="mb-0">
                        </CDBInput>
                        <div style="position: relative">
                            <CDBAutocomplete [suggestions]=colors label="Choose a Color">
                            </CDBAutocomplete>
                        </div>
                        <div class="text-center pt-3 mb-3">
                            <CDBBtn type="button" [block]=true gradient="blue" class="mx-0 z-depth-1a">
                                Sign in
                            </CDBBtn>
                        </div>
                        <p class="dark-grey-text text-right d-flex text-center justify-content-center mb-3 pt-2"
                            style="font-size: 0.8rem">
                            or Sign up with:
                        </p>
                        <div class="my-3 d-flex flex-wrap justify-content-around justify-content-md-center">
                            <CDBBtn type="button" color="white" [circle]=true class="mr-md-3 z-depth-1a">
                                <CDBIcon [fab]=true icon="facebook-f" class="blue-text text-center"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn type="button" color="white" [circle]=true class="mr-md-3 z-depth-1a">
                                <CDBIcon [fab]=true icon="twitter" class="blue-text"></CDBIcon>
                            </CDBBtn>
                            <CDBBtn type="button" color="white" [circle]=true class="z-depth-1a">
                                <CDBIcon [fab]=true icon="google-plus-g" class="blue-text"></CDBIcon>
                            </CDBBtn>
                        </div>
                    </CDBCardBody>
                </CDBCard>
            </div>
        </div>
    </div>
</section>