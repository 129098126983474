<div class="blockcode pt-5">
    <div class="header">Alert</div>
    <div class="container-fluid py-3" style="background-color: rgb(51, 51, 51)">
        <CDBAlert color="primary">
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="secondary">
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="success">
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="danger" [dismiss]=true>
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="warning">
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="dark">
            A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert color="white" [dismiss]=true>
            A simple alert built with contrast design check it out!
        </CDBAlert>
    </div>
</div>